import { Typography } from '@hailtrace/catalyst-ui';
import * as React from 'react';
import styled from 'styled-components';

interface PageSectionTitleProps {
  title: string;
}

export const PageSectionTitle: React.FC<PageSectionTitleProps> = (props) => {
  return (
    <Wrapper {...props}>
      <Typography variant="h2">{props.title}</Typography>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 12px;
  height: 24px;
`;
