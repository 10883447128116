import * as React from 'react';
import styled from 'styled-components';

interface PageProps {
  column?: boolean;
  fullWidth?: boolean;
}

export const Page: React.FC<PageProps> = ({
  column = false,
  fullWidth = false,
  ...props
}) => {
  return (
    <Wrapper column={column} fullWidth={fullWidth} {...props}>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.main<{ column: boolean; fullWidth: boolean }>`
  display: ${({ column }) => column && 'flex'};
  flex-direction: ${({ column }) => column && 'column'};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 28px 38px 0 38px;
  // height: calc(100% - 56px);
  min-height: calc(100% - 28px); /* padding top */
  width: ${({ fullWidth }) => fullWidth && '100%'};
`;
