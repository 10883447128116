import { CircularProgress } from '@hailtrace/catalyst-ui';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthenticate } from '../../useAuthenticate';
import Dashboard from '../Dashboard/Dashboard';
import { Sidebar } from './Sidebar';

const ProtectedRoutesWrapper: React.FC = ({ children }) => {
  const { authenticated } = useAuthenticate('/login');

  return (
    <>
      <Loading active={!authenticated}>
        <CircularProgress />
      </Loading>
      {authenticated && children}
    </>
  );
};

const Layout: React.FC = () => {
  return (
    <ProtectedRoutesWrapper>
      <Wrapper>
        <Sidebar />
        <PageWrapper>
          <Routes>
            <Route path="/" element={<Dashboard />} />
          </Routes>
        </PageWrapper>
      </Wrapper>
    </ProtectedRoutesWrapper>
  );
};

const PageWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.page};
`;

const Loading = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  visibility: ${({ active }) => (active && 'visible') || 'hidden'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.background.page};
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

export default Layout;
