// @ts-nocheck
import { ApolloLink } from '@apollo/client';

// @ts-ignore
const omitDeepArrayWalk = (arr, key) =>
  // @ts-ignore
  arr.map((val) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
    // @ts-ignore
    if (typeof val === 'object') return omitDeep(val, key);
    return val;
  });
// @ts-ignore
const omitDeep = (obj, key) => {
  const keys = Object.keys(obj);
  const newObj = {};
  keys.forEach((i) => {
    if (i !== key) {
      const val = obj[i];
      // @ts-ignore
      if (val instanceof Date) newObj[i] = val;
      // @ts-ignore
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
      else if (typeof val === 'object' && val !== null) {
        // @ts-ignore
        newObj[i] = omitDeep(val, key);
      }
      // @ts-ignore
      else newObj[i] = val;
    }
  });
  return newObj;
};

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, '__typename');
  }

  return forward(operation);
});

export default cleanTypenameLink;
